<template>
  <div>
    <ArrowMenu :menu="moduli"></ArrowMenu>
    <transition name="fade" mode="out-in">
      <router-view
        v-if="default_lang || isNewCampaign"
        :key="$route.path"
        @update-company="setCampaignLanguages"
      />
    </transition>
  </div>
</template>

<script>
import ArrowMenu from "../components/ArrowMenu";
import AxiosService from "./../axiosServices/AxiosService";
import { mapGetters } from "vuex";
export default {
  components: {
    ArrowMenu,
  },
  data() {
    return {
      companyLanguages: [],
      default_lang: null,
    };
  },
  service: null,
  companyService: null,
  created() {
    this.service = new AxiosService("Campaign");
    this.companyService = new AxiosService("Company");
    this.loadCampaignLanguages();
  },
  methods: {
    loadCampaignLanguages() {
      if (!this.isNewCampaign) {
        this.service.readById(this.$route.params.campaignId).then((data) => {
          this.setCampaignLanguages(data.id_company);
        });
      }
    },
    setCampaignLanguages(id_company) {
      if (id_company) {
        this.companyService.readById(id_company).then((company) => {
          if (!company.lingue) {
            company.lingue = [];
          }

          this.companyLanguages = this.languages.filter((x) =>
            company.lingue.includes(x.ID)
          );
          if (!this.companyLanguages.length) {
            this.companyLanguages = this.languages;
          }
          this.default_lang = this.companyLanguages[0].value;
        });
      }
    },
  },
  computed: {
    isNewCampaign() {
      return this.$route.params.campaignId == null;
    },
    moduli() {
      return [
        { title: "1. Details", link: "details" },
        {
          title: "2. Mission",
          link: "mission",
          disabled: this.isNewCampaign,
          locked: !this.modules["Mission"],
        },
        {
          title: "3. Learning",
          link: "learning",
          disabled: this.isNewCampaign,
          locked: !this.modules["Learning"],
        },
        {
          title: "4. Task",
          link: "task",
          disabled: this.isNewCampaign,
          locked: !this.modules["Task"],
        },
        {
          title: "5. SocialShare",
          link: "socialshare",
          disabled: this.isNewCampaign,
          locked: !this.modules["SocialShare"],
        },
        {
          title: "6. Kpis",
          link: "kpis",
          disabled: this.isNewCampaign,
          // locked: !this.modules["Kpis"],
          locked: this.modules["Kpis"], // TEMPORANEO EMMANUELE
        },
        {
          title: "7. Badges",
          link: "badges",
          disabled: this.isNewCampaign,
          locked: !this.modules["Badges"],
        },
        {
          title: "8. Awards",
          link: "awards",
          disabled: this.isNewCampaign,
          locked: !this.modules["Awards"],
        },
        {
          title: "9. Notification",
          link: "notification",
          disabled: this.isNewCampaign,
          locked: !this.modules["Notification"],
        },
      ];
    },
    ...mapGetters(["modules"]),
    ...mapGetters(["languages"]),
  },
  watch: {
    isNewCampaign: function() {
      this.loadCampaignLanguages();
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
